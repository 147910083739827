import HomeInput from "Components/HomeInput";
import { API_MAIL_CHIMP } from "Constant";
import { LinkedInLogo, TelegramLogo, TwitterXLogo } from "Icons";
import { AnimatedBackground, AnimatedBackgroundMobile } from "Videos";
import { Component } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

class HomePageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideoLoaded: false,
    };
  }
  componentDidMount() {
    var video = document.getElementById("background-creation-video");
    if (video) {
      var refreshIntervalId = setInterval(() => {
        if (video.readyState === 4) {
          this.setState({
            isVideoLoaded: true,
          });
          clearInterval(refreshIntervalId);
        }
      }, 100);
    }
  }

  render() {
    if (this.props.isMobile) {
      return (
        <>
          <div className="home-page-content-background-filter" />
          {/* <img
            alt="loch-app-background"
            src={FullBackground}
            className="home-page-content-background"
          /> */}
          <video
            className="home-page-content-background"
            autoPlay
            loop
            muted
            playsInline
            id="background-creation-video"
          >
            <source src={AnimatedBackgroundMobile} type="video/mp4" />
          </video>

          <div className="home-page-content">
            <div className="home-page-content-data">
              <div className="home-page-content-data-top">
                <h1 className="h-p-c-d-c-heading-text">
                  Truth Seeking Markets
                </h1>
                <p className="h-p-c-d-c-desc">
                  Fight disinformation with skin in the game
                </p>
                {/* <div className="h-p-c-d-c-heading-btn">INVITE ONLY</div> */}
              </div>
              <div className="home-page-content-data-bottom">
                <MailchimpSubscribe
                  url={API_MAIL_CHIMP}
                  render={({ subscribe, status, message }) => (
                    <HomeInput
                      subscribe={subscribe}
                      status={status}
                      message={message}
                    />
                  )}
                />
                <div className="h-p-c-d-c-extra">
                  <p className="h-p-c-d-c-name-year">Loch, Inc. © 2025</p>
                  <div className="home-page-content-data-top-socials">
                    {/* <a
                      href="https://www.loch.one/modulus-demo"
                      target="_blank"
                      rel="noreferrer"
                      className="home-page-content-data-top-socials-link"
                    >
                      <div className="home-page-content-data-top-socials-link-btn">
                        Demo
                      </div>
                    </a>
                    <a
                      href="https://app.loch.one"
                      // href="https://zestful-engineers-660923.framer.app/"
                      target="_self"
                      rel="noreferrer"
                      className="home-page-content-data-top-socials-link"
                    >
                      <div className="home-page-content-data-top-socials-link-btn">
                        Wallet
                      </div>
                    </a> */}
                    <a
                      href="https://blog.loch.one"
                      // href="https://zestful-engineers-660923.framer.app/"
                      target="_self"
                      rel="noreferrer"
                      className="home-page-content-data-top-socials-link"
                    >
                      <div className="home-page-content-data-top-socials-link-btn">
                        Blog
                      </div>
                    </a>
                    <a
                      href="https://x.com/factcheckdotfun"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="twitter-x-logo"
                        className="home-page-content-data-top-social-images-icons"
                        src={TwitterXLogo}
                      />
                    </a>
                    <a
                      href="https://t.me/+pS165T_hWHE1ZmQx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="telegram-logo"
                        className="home-page-content-data-top-social-images-icons"
                        src={TelegramLogo}
                      />
                    </a>

                    <a
                      href="https://www.linkedin.com/company/loch-inc/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="linkedin-logo"
                        className="home-page-content-data-top-social-images-icons"
                        src={LinkedInLogo}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-page-content-legal">
              The content made available on this web page and our mobile
              applications ("Platform") is for informational purposes only. You
              should not construe any such information or other material as
              financial advice in any way. All information provided on the
              Platform is provided on an as is and available basis, based on the
              data provided by the end user on the Platform. Nothing contained
              on our Platform constitutes a solicitation, recommendation,
              endorsement, or offer by us or any third-party service provider to
              buy or sell any securities or other financial instruments in this
              or in any other jurisdiction in which such solicitation or offer
              would be unlawful under the securities laws of such jurisdiction.
              All content on this Platform is information of a general nature
              and does not address the circumstances of any particular
              individual or entity. Nothing in the Platform constitutes
              financial advice, nor does any information on the Platform
              constitute a comprehensive or complete statement of the matters
              discussed or the law relating thereto. You alone assume the sole
              responsibility of evaluating the merits and risks associated with
              the use of any information or other content on the platform before
              making any decisions based on such information. In exchange for
              using the Platform, you agree not to hold us, our affiliates, or
              any third-party service provider liable for any possible claim for
              damages arising from any decision you make based on information or
              other content made available to you through the Platform.
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div
          style={{
            opacity: this.state.isVideoLoaded ? 1 : 0,
          }}
        >
          <div className="home-page-content-background-filter" />
          <video
            className="home-page-content-background"
            autoPlay
            loop
            muted
            playsInline
            id="background-creation-video"
          >
            <source src={AnimatedBackground} type="video/mp4" />
          </video>
        </div>
        <div className="home-page-content">
          <div className="home-page-content-data">
            <div className="home-page-content-data-top-socials">
              {/* <a
                href="https://www.loch.one/modulus-demo"
                target="_blank"
                rel="noreferrer"
                className="home-page-content-data-top-socials-link"
              >
                <div className="home-page-content-data-top-socials-link-btn">
                  Modulus Demo
                </div>
              </a>
              <a
                href="https://app.loch.one"
                target="_self"
                rel="noreferrer"
                className="home-page-content-data-top-socials-link"
              >
                <div className="home-page-content-data-top-socials-link-btn">
                  Wallet Search
                </div>
              </a> */}
              <a
                href="https://blog.loch.one"
                // href="https://zestful-engineers-660923.framer.app/"
                target="_self"
                rel="noreferrer"
                className="home-page-content-data-top-socials-link"
              >
                <div className="home-page-content-data-top-socials-link-btn">
                  Blog
                </div>
              </a>
              <a
                href="https://x.com/factcheckdotfun"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="twitter-x-logo"
                  className="home-page-content-data-top-social-images-icons"
                  src={TwitterXLogo}
                />
              </a>
              <a
                href="https://t.me/+pS165T_hWHE1ZmQx"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="telegram-logo"
                  className="home-page-content-data-top-social-images-icons"
                  src={TelegramLogo}
                />
              </a>

              <a
                href="https://www.linkedin.com/company/loch-inc/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="linkedin-logo"
                  className="home-page-content-data-top-social-images-icons"
                  src={LinkedInLogo}
                />
              </a>
            </div>
            <div className="home-page-content-data-center">
              <div className="h-p-c-d-c-heading">
                <h1 className="h-p-c-d-c-heading-text">
                  Truth Seeking Markets
                </h1>
                {/* <div className="h-p-c-d-c-heading-btn">INVITE ONLY</div> */}
              </div>
              <p className="h-p-c-d-c-desc">
                Fight disinformation with skin in the game
              </p>

              <MailchimpSubscribe
                url={API_MAIL_CHIMP}
                render={({ subscribe, status, message }) => (
                  <HomeInput
                    subscribe={subscribe}
                    status={status}
                    message={message}
                  />
                )}
              />
            </div>
          </div>
          <div className="home-page-content-legal">
            The content made available on this web page and our mobile
            applications ("Platform") is for informational purposes only. You
            should not construe any such information or other material as
            financial advice in any way. All information provided on the
            Platform is provided on an as is and available basis, based on the
            data provided by the end user on the Platform. Nothing contained on
            our Platform constitutes a solicitation, recommendation,
            endorsement, or offer by us or any third-party service provider to
            buy or sell any securities or other financial instruments in this or
            in any other jurisdiction in which such solicitation or offer would
            be unlawful under the securities laws of such jurisdiction. All
            content on this Platform is information of a general nature and does
            not address the circumstances of any particular individual or
            entity. Nothing in the Platform constitutes financial advice, nor
            does any information on the Platform constitute a comprehensive or
            complete statement of the matters discussed or the law relating
            thereto. You alone assume the sole responsibility of evaluating the
            merits and risks associated with the use of any information or other
            content on the platform before making any decisions based on such
            information. In exchange for using the Platform, you agree not to
            hold us, our affiliates, or any third-party service provider liable
            for any possible claim for damages arising from any decision you
            make based on information or other content made available to you
            through the Platform.
          </div>
        </div>
      </>
    );
  }
}

export default HomePageContent;
